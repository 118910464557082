import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { collection, onSnapshot, query, where, addDoc } from 'firebase/firestore';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { DISPUTE_COLLECTION_NAME } from '../../constants/data';
import { db } from '../../utils/firebase';
import Input from '../Input';
import CloseIcon from '../../assets/icons/close.svg';
import Button from '../Button';
import { openDisputeMail } from '../../services/mailService';
import { userStore } from '../../store/userStore';
import { IDispute } from '../../constants/types';

const paperStyle = {
  width: 600,
  padding: '2rem',
  borderRadius: '8px',
  background: 'white',
  border: '1px solid #EDE7DF',
  position: 'relative',
};

const headingStyle = {
  fontSize: '24px',
  fontWeight: 600,
  fontFamily: 'Inter',
  wordWrap: 'break-word',
  textAlign: 'center',
};

const initialValues = {
  issues: '',
  description: '',
  response: '',
};

const RaiseDisputeModal = ({
  user,
  sessionId,
  closeModal,
}: {
  user: any;
  sessionId: string;
  closeModal: any;
}) => {
  const storeUser = userStore((store) => store.user);
  const profileData = userStore((store) => store.profileData);
  const [response, setResponse] = useState<IDispute | null>(null);
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      if (!storeUser) return;

      console.log(values);

      const colRef = collection(db, DISPUTE_COLLECTION_NAME);

      // Add the data to the Firestore collection
      await addDoc(colRef, {
        sessionId,
        userId: user,
        ...values,
        status: 'pending',
        dateOfIssue: new Date(),
      });
      await openDisputeMail({
        email: storeUser.email as string,
        username: profileData?.displayName || 'user',
        description: values.description,
        issues: values.issues,
      });

      closeModal();
      console.log('Data added to the collection.');
    } catch (error) {
      console.error('Error adding data to the collection:', error);
    }
  };

  useEffect(() => {
    const colRef = collection(db, DISPUTE_COLLECTION_NAME);
    const q = query(colRef, where('sessionId', '==', sessionId));

    const getDispute = onSnapshot(
      q,
      (snapshot) => {
        const slots: any[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          slots.push({
            id: doc.id,
            ...data,
          });
        });

        console.log(slots);
        if (slots.length) {
          setResponse(slots[0]);
        }

        setLoading(false);

        // setDisputeStatus(dispute.issues && dispute.description ? 'View' : 'Raise');
      },
      (error) => {
        console.error('Error fetching upcoming sessions:', error);
      }
    );

    return () => {
      getDispute();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object({
    issues: Yup.string()
      .max(250, 'maximum 250 characters is allowed')
      .required('This field is required'),
    description: Yup.string()
      .max(250, 'maximum 250 characters is allowed')
      .required('This field is required'),
    response: Yup.string(),
  });

  const formValues = response
    ? {
        issues: response.issues,
        description: response.description,
        response: response.response,
      }
    : null;

  return (
    <Paper sx={paperStyle}>
      {loading ? (
        <Stack direction="row" justifyContent={'center'}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Typography sx={headingStyle}>{response ? 'View' : 'Raise'} Dispute</Typography>
          <img
            src={CloseIcon}
            alt="close-icon"
            style={{ position: 'absolute', top: '2rem', right: '2rem', cursor: 'pointer' }}
            onClick={closeModal}
          />
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <StyledGrid>
                  <div>
                    <Input
                      name="issues"
                      readOnly={Boolean(response)}
                      label="What issues did you face?"
                    />
                  </div>
                  <DescriptionContainer>
                    <p>Description</p>
                    <DescriptionInputContainer>
                      <Field
                        as="textarea"
                        readOnly={Boolean(response)}
                        placeholder={''}
                        name="description"
                        rows={5}
                      />
                    </DescriptionInputContainer>
                    <ErrorMessage name="description" className="error-input" component="div" />
                  </DescriptionContainer>
                  {/* {dispute.response ? (
                <Input
                  name="response"
                  label="How we solved it?"
                  value={formData.response}
                  readOnly={!!(dispute.issues && dispute.description)}
                />
              ) : (
                <>
                  {dispute.issues && dispute.description && (
                    <div style={{ color: 'var(--error)', fontWeight: '500' }}>
                      We are figuring out a way to solve your dispute. We will get back to you soon.
                    </div>
                  )}
                </>
              )} */}
                  {response ? (
                    response.response ? (
                      <Input name="response" label="How we solved it?" readOnly={true} />
                    ) : (
                      <div style={{ color: 'var(--error)', fontWeight: '500' }}>
                        We are figuring out a way to solve your dispute. We will get back to you
                        soon.
                      </div>
                    )
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <Button variant="primary" type="submit" style={{ width: '100%' }}>
                        Submit
                      </Button>
                    </div>
                  )}
                </StyledGrid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Paper>
  );
};

const StyledGrid = styled.div`
  padding: 2rem 0 0;
`;

const DescriptionContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    display: block;
    color: var(--text-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .error-input {
    color: tomato;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const DescriptionInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #ede7df;

  > textarea {
    border-radius: 8px;
    outline: none;
    border: none;
    color: var(--black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 15px;
    resize: none;
    font-family: 'Inter', sans-serif;

    &:placeholder {
      color: var(--gray-3);
      font-size: 14px;
      line-height: 1.2;
    }
  }

  ol {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      font-size: 14px;
    }
  }
`;

export default React.memo(RaiseDisputeModal);
