import axios from 'axios';
import { config } from '../constants/config';
import { addDoc, collection } from 'firebase/firestore';
import { Mail_COLLECTION_NAME } from '../constants/data';
import { db } from '../utils/firebase';

interface ISendBookingConfirmationMail {
  userId: string;
  tutorId: string;
  date: string;
  time: string;
}

export const sendBookingConfirmationMail = async ({
  userId,
  tutorId,
  date,
  time,
}: ISendBookingConfirmationMail) => {
  await axios.post(`${config.BACKEND_URL}/mail/booking-confirmation`, {
    userId,
    tutorId,
    date,
    time,
  });
};

export const sendBookingCancellationMail = async ({
  userId,
  tutorId,
  date,
  time,
}: ISendBookingConfirmationMail) => {
  await axios.post(`${config.BACKEND_URL}/mail/session-cancellation`, {
    userId,
    tutorId,
    date,
    time,
  });
};

export const openDisputeMail = async ({
  email,
  username,
  issues,
  description,
}: {
  email: string;
  username: string;
  issues: string;
  description: string;
}) => {
  const colRef = collection(db, Mail_COLLECTION_NAME);
  await addDoc(colRef, {
    to: [email],
    bcc: 'arunkutty798@gmail.com',
    template: {
      name: 'OpenDisputeByUser',
      data: {
        username,
        issues,
        description: description || '-',
      },
    },
  });
};
