export const config = {
  TUTOR_FEE_PER_SESSION: 100,
  PER_SESSION_RECORDING_FEE: Number(process.env.REACT_APP_PER_SESSION_RECORDING_FEE || 50),
  DEMO_CLASS_FEE: 99,
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  VIDEOSDK_TOKEN: process.env.REACT_APP_VIDEOSDK_TOKEN,
  RECORDING_API: 'https://api.videosdk.live/v2/recordings',

  CANCEL_SESSION_COUNT: process.env.REACT_APP_CANCEL_SESSION_COUNT || 2,
  CANCEL_DEMO_SESSION_COUNT: process.env.REACT_APP_CANCEL_DEMO_SESSION_COUNT || 1,
  SESSION_DURATION: process.env.REACT_APP_SESSION_DURATION || 29,
  DEMO_CLASS_SESSION_DURATION: process.env.REACT_APP_DEMO_CLASS_SESSION_DURATION || 15,
  SHOW_AVAILABLE_SLOTS_BEFORE: 60, // 60 minutes
  CANCEL_SESSION_DURATION_IN_HRS: 2, // BEFORE 2 HRS
  EDIT_SESSION_DURATION_IN_HRS: 1, // BEFORE 2 HRS
  NO_OF_QUESTIONS: Number(process.env.NO_OF_QUESTIONS) || 5,

  // DEFAULT TEXT MESSAGES

  NO_FEEDBACK_MESSAGE:
    'Complete atleast 10 sessions to generate your detailed feedback analysis report',
};
